<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <SessionsTable></SessionsTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SessionsTable from "./tables/SessionsTable.vue";
export default {
  name: "Sitzungen",
  components: {
    SessionsTable,
  },
  data() {
    return {
      msg: "Sitzungen",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
